* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
h1,
h2,
h3 {
  font-weight: 600;
  color: #2b2b2b;
}
body {
  background: #8360c3; 
  background: -webkit-linear-gradient(
    to right,
    #2ebf91,
    #8360c3
  ); 
  background: linear-gradient(
    to right,
    #2ebf91,
    #8360c3
  );

  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}

@import "./song";
@import "./player";
@import "./library";
@import "./nav";
