.song-container {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img{
    width: 20%;
    border-radius: 50%;
  }
  h2{
    color: white;
    padding: 3rem 1rem 1rem 1rem;
  }
  h3{
    color: white;
    font-size: 1rem;
  }
}
